<template>
  <v-container class="contenedor-main">
    <v-row>
      <tool-bar-nav-list
        :items="[{ items: listaPeriodos, value: busquedaPe, label: 'Periodo' }]"
        v-on:toggle="searchC($event)"
      ></tool-bar-nav-list>
    </v-row>
    <v-row>
      <tool-bar-nav
        v-if="mostrarTable == true"
        :pageRow="{ value: pageLengthPer, type: 'clientesRHeigth' }"
        :editColsBottom="{ badgeText: 'Editar cols' }"
        :dataColumns="{
          colsShow: columnasMostrar,
          colsList: listaColumnas,
          endpointEdit: 'editar_cartera',
          materia: null,
          periodo: null,
        }"
        :refreshBottom="{ badgeText: 'Reestablecer cols' }"
        :deleteBottom="{
          badgeText: 'Eliminar venta',
          tipoEndpoint: 'borrar',
          endpoint: '/ventas',
          atributo: 'ventaId',
        }"
        :editRowBottom="{
          badgeText: 'Editar venta',
          tipo: 'editVenta',
        }"
        :copyRowBottom="{ badgeText: 'Copiar venta' }"
        :exportBottom="{
          badgeText: 'Exportar tabla',
          nameDoc: 'Ventas',
        }"
        :histBottom="{ show: rigthClick, data: historial }"
        :colorBottom="{ show: rigthClick }"
        :textoActionToolBar="{ text: textoActionToolBar }"
        :selectedRow="onSelected"
        :grid="{ idGrid: $refs.grid }"
        v-on:toggle="eventosClientesRoot($event)"
        v-on:texto-tool="eventosTextTool($event)"
        v-on:color-item="eventosColor($event, asignarColor)"
      >
      </tool-bar-nav>
      <v-container
        class="contenedor-tabla"
        v-show="busquedaPe != null && mostrarTable == true"
      >
        <vue-excel-editor
          ref="grid"
          v-if="mt"
          v-show="mostrarTable == true"
          :page="pageLengthPer"
          v-model="jsondata"
          filter-row
          :localized-label="myLabels"
          remember
          @cell-click="position"
          v-on:toggle-table="activeRigthClick($event)"
          @cell-focus="getLabelColor"
          :cell-style="metodoColorBack"
          @select="onSelect"
          :enterToSouth="enterAbajo"
        >
          <vue-excel-column
            field="_id"
            label="_id"
            type="string"
            :invisible="true"
          />
          <vue-excel-column
            field="Fecha de venta"
            label="Fecha de venta"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="lineaW"
            label="lineaW"
            :validate="valid"
            type="number"
          />
          <vue-excel-column
            field="Confirmo"
            label="Confirmo"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Teléfono 2"
            label="Teléfono 2"
            type="string"
          />
          <vue-excel-column
            field="Medio de venta"
            label="Medio de venta"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Mat. Total"
            label="Mat. Total"
            :validate="valid"
            type="number"
          />
          <vue-excel-column
            field="Materias"
            label="Materias"
            :change="openMaterias"
            type="select"
            :options="['Ver materias']"
          />
          <vue-excel-column
            field="Cursos"
            label="Cursos"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Periodo"
            label="Periodo"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Nombre"
            label="Nombre"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Cédula"
            label="Cédula"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Teléfono"
            label="Teléfono"
            type="number"
            :validate="valid"
          />
          <vue-excel-column
            field="Usuario"
            label="Usuario"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Contraseña"
            label="Contraseña"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="País"
            label="País"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Servidor"
            label="Servidor"
            :validate="valid"
            type="number"
          />
          <vue-excel-column
            field="Moodle"
            label="Moodle"
            :validate="valid"
            type="number"
          />
          <vue-excel-column
            field="Correo"
            label="Correo"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Contraseña del correo"
            label="Contraseña del correo"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Edad"
            label="Edad"
            :validate="valid"
            type="number"
          />
          <vue-excel-column
            field="CEAD"
            label="CEAD"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Carrera"
            label="Carrera"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Género"
            label="Género"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="LINK DRIVE"
            label="LINK DRIVE"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Valor acordado"
            label="Valor acordado"
            :validate="valid"
            type="number"
            :sort="sortByNumA"
          />
          <vue-excel-column
            field="Valor recaudado"
            label="Valor recaudado"
            :validate="valid"
            type="number"
            :sort="sortByNumRE"
          />
          <vue-excel-column
            field="Valor restante"
            label="Valor restante"
            :validate="valid"
            type="number"
            :sort="sortByNumR"
          />
          <vue-excel-column
            field="Notas"
            label="Notas"
            type="number"
            :validate="valid"
          />
          <vue-excel-column
            field="Activo"
            label="Activo"
            type="select"
            :options="['DPS', 'LAZARO', 'OK']"
            :change="cambiarAlerta"
          />
          <vue-excel-column
            field="WhatsApp"
            label="WhatsApp"
            :change="openWA"
            type="select"
            :options="['Ir a WhatsApp']"
            key-field
            :invisible="false"
          />
          <vue-excel-column
            field="Pagos"
            label="Pagos"
            :change="openPagos"
            type="select"
            :options="['Ver pagos']"
            key-field
            :invisible="false"
          />
          <vue-excel-column
            field="Editar venta"
            label="Editar venta"
            :change="editV"
            type="select"
            :options="['Editar ventas']"
            key-field
            :invisible="false"
          />
        </vue-excel-editor>
      </v-container>
      <template>
        <!-- Dialog ver materias -->
        <v-dialog v-if="dialog" v-model="dialog" scrollable max-width="70%">
          <venta-ver-materias
            v-if="dialog"
            :dataCursos="dataCursos"
            v-on:verMaterias="eventoVerMaterias"
            :info="infoVerMaterias"
            :infoCli="infoCli"
          >
          </venta-ver-materias>
        </v-dialog>
        <!--EDITAR LA VENTA  -->
        <v-dialog v-model="editVenta" max-width="80%">
          <venta-view
            v-if="editVenta == true"
            :title="'Editar la venta de '"
            :nameCard="nameCard"
            :info="venta"
            :idCli="idCli"
            :fechasV="dates"
            :frecV="frec"
            v-on:toggle="eventosVenta($event)"
          ></venta-view>
        </v-dialog>
        <!-- dialog de WA -->
        <v-dialog v-if="dialogW" v-model="dialogW" max-width="30%">
          <whats-app
            v-on:eventosWa="eventosWApp"
            :name="nameCard"
            :optionsW="optionsW"
          ></whats-app>
        </v-dialog>
        <v-dialog v-model="dialogPagos" max-width="95%">
          <registrar-pagos
            :cuotasTotales="cuotasTotales"
            v-on:toggle="togglePagos($event)"
            :cliente="nameCard"
            :listaPagos="listaPagos"
            :ventaId="ventaId"
            :valorAcordado="valorAcordado"
            :valorRecaudado="valorRecaudado"
            :valorRestante="valorRestante"
            :tipo="'normal'"
          ></registrar-pagos>
        </v-dialog>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
// import WhatsApp from "./component/WhatsApp.vue";
// import ventaView from "./component/VentaView.vue";
import ToolBarNavList from "./component/ToolBarNavList.vue";
import ToolBarNav from "./component/ToolBarNav.vue";
import infoSelected from "./Tools/itemSelected";
// import VentaVerMaterias from "./component/VentaVerMaterias.vue";

import tablesMixin from "../mixins/tablas";
// import RegistrarPagos from "./component/RegistrarPagos.vue";

export default {
  mixins: [tablesMixin],

  components: {
    ToolBarNavList,
    ToolBarNav,

    WhatsApp: () =>
      import(/* webpackChunkName: "WhatsApp"  */ "./component/WhatsApp.vue"),
    ventaView: () =>
      import(/* webpackChunkName: "ventaView"  */ "./component/VentaView.vue"),
    VentaVerMaterias: () =>
      import(
        /* webpackChunkName: "VentaVerMaterias"  */ "./component/VentaVerMaterias.vue"
      ),
    RegistrarPagos: () =>
      import(
        /* webpackChunkName: "RegistrarPagos"  */ "./component/RegistrarPagos.vue"
      ),
    // WhatsApp,
    // ventaView,
    // VentaVerMaterias,
    // RegistrarPagos,
  },

  data() {
    return {
      textoActionToolBar: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: "",
        precision: 1,
        masked: false,
      },
      // buscando: false,
      dialog: false,
      materias: "Ver materias",
      listaCursos: [],
      jsondata: [],
      dataCursos: [{ curso: null, estado: null, obs: null }],
      infoCli: [],
      editVenta: false,
      venta: {
        linea: null,
        medioVenta: null,
        materiasRepo: null,
        agenteVenta: null,
        periodo: null,
        materias: [],
        valorAcordado: null,
        notas: null,
        cuotasTotales: null,
      },
      listaColumnas: [],
      columnasMostrar: [],
      mostrarTable: false,
      dialogPagos: false,
      listaPagos: {},
      cuotasTotales: 0,
      valorAcordado: null,
      valorRestante: null,
      valorRecaudado: null,
      date: new Date(),
      pageLengthPer: this.$store.state.clientesRHeigth,
      nameCard: null,
      optionsW: [],
      dialogW: false,
      dataWA: [],
      rowWA: null,
      activoByM: [],
      onSelected: null,
      busquedaPe: null,
      listaPeriodos: [],
      ventaId: null,
      mt: false,
      infoVerMaterias: [],
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      haveCellsColor: false,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
      rowFilter: [],
    };
  },

  watch: {
    busquedaPe: function (v) {
      if (v != null) {
        this.initialize();
      }
    },
  },

  async created() {
    await this.cargueColors();
  },

  mounted() {
    document.title = "Ventas";
    this.periodosGet();
  },

  methods: {
    async initialize() {
      this.mostrarTable = false;
      this.listaColumnas = [];
      this.columnasMostrar = [];
      this.mt = false;
      await this.cargueColors();
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "listar");
          fd.append("periodo", this.busquedaPe);
          axios.post(host + "/ventas", fd).then((result) => {
            let data = result.data.lista;
            // this.buscando = false;

            if (result.data.text == "OK") {
              this.pageLength = data.length;
              for (let i = 0, len = data.length; i < len; i++) {
                var vA = Intl.NumberFormat("de-DE").format(
                  data[i]["Valor acordado"]
                );
                var vR = Intl.NumberFormat().format(data[i]["Valor recaudado"]);
                var vRes = Intl.NumberFormat().format(
                  data[i]["Valor restante"]
                );
                data[i]["Valor acordado"] = vA;
                data[i]["Valor recaudado"] = vR;
                data[i]["Valor restante"] = vRes;
                data[i]["Confirmo"] =
                  data[i]["Confirmo"] == "undefined" ||
                  data[i]["Confirmo"] == "null"
                    ? "-"
                    : data[i]["Confirmo"];
                data[i]["Género"] =
                  data[i]["Género"] == "undefined"
                    ? "-"
                    : data[i]["Género"] == "null"
                    ? "-"
                    : data[i]["Género"];
                data[i]["CEAD"] =
                  data[i]["CEAD"] == "null" ? "-" : data[i]["CEAD"];
                data[i]["Carrera"] =
                  data[i]["Carrera"] == "null" ? "-" : data[i]["Carrera"];
                data[i]["Medio de venta"] =
                  data[i]["Medio de venta"] == "null"
                    ? "-"
                    : data[i]["Medio de venta"];
                data[i]["Carrera"] =
                  data[i]["Carrera"] == "null" ? "-" : data[i]["Carrera"];
                data[i]["lineaW"] =
                  data[i]["lineaW"] == "null" ? "-" : data[i]["lineaW"];
                this.dataWA.push({
                  id: data[i]["_id"],
                  data: data[i]["WhatsApp"],
                });
                data[i]["WhatsApp"] = data[i]["WhatsApp"] == "null" ? "-" : "";
                data[i]["Materias Repo"] =
                  data[i]["Materias Repo"] == "null" ? "-" : "";
                data[i]["Fecha de venta"] = this.$moment(
                  data[i]["Fecha de venta"]
                ).format("YYYY/MM/DD - HH:mm:ss");
                data[i]["Pagos"] = data[i]["Pagos"] == "null" ? "-" : "";
              }
              this.jsondata = data;
              if (this.jsondata.length == 0) {
                this.$alert("No existen ventas");
              } else {
                this.mostrarTable = true;
                this.listaColumnas = result.data.visibilidad;
                for (var k = 0; k < this.listaColumnas.length; k++) {
                  if (this.listaColumnas[k]["valor"] == true) {
                    this.columnasMostrar.push(this.listaColumnas[k]);
                  }
                }
                this.cargueCol(["Editar venta", "Pagos", "WhatsApp"]);
              }
            } else {
              this.$alert(result.data.text);
            }
          });
          this.mt = true;
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    periodosGet() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "cursos_habilitados");
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data;
            this.listaPeriodos = data.listaPeriodos;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    openMaterias(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      this.infoCli = [];
      this.infoVerMaterias = row;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "por_venta");
          fd.append("ventaId", row._id);
          axios.post(host + "/alertas", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              var info = data.lista;
              this.dataCursos = [];
              for (var i = 0; i < info.length; i++) {
                this.dataCursos.push({
                  curso: info[i].materia,
                  estado: info[i].estado,
                  obs: info[i].observaciones,
                });
              }
              this.infoCli = row;
              this.dialog = true;
            } else {
              this.$alert("Error: " + data);
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
      return false;
    },

    openPagos(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      this.rowFilter = this.jsondata.indexOf(row);
      this.dialogPagos = true;
      this.listaPagos = row.pagos;
      this.cuotasTotales = row.cuotasTotales;
      this.ventaId = row.ventaId;
      this.valorAcordado = row.valorAcordado;
      this.valorRestante = row.saldoRestante;
      this.valorRecaudado = row.valorRecaudado;
      this.nameCard = row.nombre;
      return false;
    },

    editV(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      this.venta = row;
      this.idCli = row._id;
      this.venta.materias = [];
      this.venta.agenteVenta = row.agenteVentas;
      for (var m = 0; m < row.cursos.length; m++) {
        this.venta.materias.push(row.cursos[m]);
      }
      this.nameCard = row.nombre;
      this.dates = [];
      for (var d = 0; d < row.pagos.length; d++) {
        this.dates.push(
          this.$moment(row.pagos[d]["fechaAcordada"]).format("YYYY-MM-DD")
        );
      }
      var a = this.$moment(this.dates[1]);
      var b = this.$moment(this.dates[0]);
      var duration = a.diff(b, "days");
      if (duration == "15") {
        this.frec = 15;
      }
      if (duration == "30") {
        this.frec = 30;
      }
      if (duration == "7") {
        this.frec = 7;
      }
      this.editVenta = true;
      return false;
    },

    openWA(oldVal, newVal, row, field) {
      console.log(field);
      this.dialogW = true;
      this.nameCard = row.nombre;
      const resObject = this.dataWA.find((item) => item.id === row._id);
      this.optionsW = resObject.data;
      return false;
    },

    closeWA() {
      this.dialogW = false;
      this.rowWA = null;
      this.nameCard = null;
    },

    cambiarAlerta(newVal, oldVal, row) {
      this.textoActionToolBar.push("Guardando " + '"' + newVal + '"');
      console.log(oldVal);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "venta_cartera");
          fd.append("ventaId", row.ventaId);
          fd.append("status", newVal.trim());
          axios.post(host + "/alertas", fd).then((result) => {
            let data = result.data.text;
            var rem = this.textoActionToolBar.indexOf(
              "Guardando " + '"' + newVal + '"'
            );
            if (data != "OK") {
              this.$alert(
                "Error al guardar el ajuste de estado de usuario, intentelo nuevamente"
              );
              this.textoActionToolBar.splice(rem, 1);
              this.textoActionToolBar.push(
                "Error al guardar " + '"' + newVal + '"'
              );
              return false;
            } else {
              this.textoActionToolBar.splice(rem, 1);
              this.buscar();
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    sortByNumA(a, b) {
      if (a["valorAcordado"] > b["valorAcordado"]) {
        return 1;
      }
      if (a["valorAcordado"] < b["valorAcordado"]) {
        return -1;
      }
      return 0;
    },

    sortByNumR(a, b) {
      if (a["saldoRestante"] > b["saldoRestante"]) {
        return 1;
      }
      if (a["saldoRestante"] < b["saldoRestante"]) {
        return -1;
      }
      return 0;
    },

    sortByNumRE(a, b) {
      if (a["valorRecaudado"] > b["valorRecaudado"]) {
        return 1;
      }
      if (a["valorRecaudado"] < b["valorRecaudado"]) {
        return -1;
      }
      return 0;
    },

    onSelect() {
      this.onSelected = infoSelected(this.$refs.grid, "_id");
      if (this.onSelected != null) {
        this.onSelected["mensaje"] =
          "la venta de " +
          this.onSelected["field"]["Usuario"] +
          " de el/los curso/s " +
          this.onSelected["field"]["Cursos"] +
          " - " +
          this.onSelected["field"]["Periodo"];
      }
      this.rigthClick = false;
    },

    searchC(a) {
      if (a == "blur") {
        this.$refs.grid.inputBoxBlur();
      } else {
        var element = a[0];
        if (element.label == "Periodo") this.busquedaPe = element.value;
      }
    },

    // EVENTOS
    togglePagos(x) {
      if (x == true) {
        this.initialize();
        this.dialogPagos = false;
      }
      if (x.cantidad) {
        this.jsondata[this.rowFilter]["Valor recaudado"] =
          Intl.NumberFormat().format(
            this.jsondata[this.rowFilter]["valorRecaudado"] +
              parseInt(x.cantidad)
          );
        this.jsondata[this.rowFilter]["Valor restante"] =
          Intl.NumberFormat().format(
            this.jsondata[this.rowFilter]["saldoRestante"] -
              parseInt(x.cantidad)
          );
        this.dialogPagos = false;
      }
      if (x == false) {
        this.dialogPagos = false;
      }
    },

    eventosClientesRoot(d) {
      if (d == "refresh" || d == "edit") {
        this.initialize();
      }
      if (d == "editVenta") {
        this.editV("", "", this.onSelected.field);
      }
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },

    eventosVenta(v) {
      if (v == "close") {
        this.editVenta = false;
      }
      if (v == "refresh") {
        this.editVenta = false;
        this.initialize();
      }
    },

    eventoVerMaterias(d) {
      if (d == false) this.dialog = false;
    },

    eventosWApp(d) {
      if (d == false) this.dialogW = false;
    },
  },
};
</script>